import React, { useReducer } from "react";
import "./App.css";
import Masonry from "./components/Masonry";
import SpeedDialButton from "./components/SpeedDialButton";
import Grid from "@material-ui/core/Grid";
import Title from "./components/Title";

export const Context = React.createContext();

const initialState = {
  typed: false
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TYPE":
      return (state = { ...state, typed: action.value });
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <>
      <Context.Provider value={{ state, dispatch }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Title />
          </Grid>
          <Grid item xs={12} md={12}>
            <Masonry />
          </Grid>
        </Grid>
        <SpeedDialButton />
      </Context.Provider>
    </>
  );
}

export default App;
