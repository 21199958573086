import beer from "../assets/beer-menu.png";
import boruto from "../assets/boruto-react.png";
import clarifai from "../assets/clarifai-image-detection.png";
import destiny from "../assets/destiny-landing.png";
import foodMenu from "../assets/food-menu.png";
import geodiary from "../assets/geodiary.png";
import landingAnimated from "../assets/lading-page-animated.png";
import landingAnimated2 from "../assets/landing-page-animated-2.png";
import htmlAnimation from "../assets/htmlAnimation.png";
import pokedex from "../assets/pokedex.png";
import pokedexDrag from "../assets/pokedexDrag.png";
import rickMorty from "../assets/rickMorty.png";
import simpleStore from "../assets/simpleStore.png";

export default [
  {
    css: beer,
    height: 780,
    github: "https://github.com/eduardolanda/BeerMenu",
    demo: "http://beermenureact.s3-website-us-west-2.amazonaws.com",
    description:
      "This is a web react app connected to an API that gives you information relation to different beers and their origin, also their website.",
    title: "Beer Menu"
  },
  {
    css: boruto,
    height: 700,
    github: "www.github.com",
    demo: "http://borutoscrolling.s3-website-us-east-1.amazonaws.com",
    description:
      "This landing react page loads a video that plays according to the scrolling from the user.",
    title: "Boruto Scrolling"
  },
  {
    css: clarifai,
    height: 720,
    demo: "http://clarifaidetection.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/clarifaidetectionreact",
    description:
      "React web app to inspect images by image detection, we can type a word or add a link to an image.",
    title: "Image Detection"
  },
  {
    css: destiny,
    height: 760,
    demo: "http://destinylanding.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/destinythegamereact",
    description:
      "Landing page using Spring with React, this allows to manipulate item animation as parallax.",
    title: "Spring Landing Page"
  },
  {
    css: foodMenu,
    height: 740,
    demo: "http://foodmenureact.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/",
    description:
      "React app to get recipes after typing any kind of ingredient, It is connected to an API using axios.",
    title: "Food Menu"
  },
  {
    css: landingAnimated,
    height: 700,
    demo: "http://landigpageanimated.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/",
    description: "React SVG animated landing page",
    title: "React SVG Animation"
  },
  {
    css: landingAnimated2,
    height: 700,
    demo: "http://ledlightsvg.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/",
    description: "Simple logo animated in a React Landing page.",
    title: "React SVG Animation 2"
  },
  {
    css: geodiary,
    height: 700,
    demo: "http://geodiary.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/GeoDiary",
    description:
      "It is a react web app that allows to store information as a diary and a location with a map from mapbox.",
    title: "Geodiary"
  },
  {
    css: htmlAnimation,
    height: 700,
    demo: "http://logoanimatededuardo.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/",
    description:
      "Logo animated with pure HTML, this logo was created to add to my personal logo",
    title: "Animation HTML Logo"
  },
  {
    css: pokedex,
    height: 700,
    demo: "http://pokedexeduardo.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/PokedexFunctionalComponents",
    description:
      "React Pokedex connected to POKE API using only functional components",
    title: "Pokedex functional components"
  },
  {
    css: pokedexDrag,
    height: 700,
    demo: "http://pokemonshooksanimated.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/pokemonshooksapianimated",
    description:
      "React Pokedex connected to POKE API using only hooks and dragging option",
    title: "Pokedex Hooks components"
  },
  {
    css: rickMorty,
    height: 700,
    demo: "http://reactandmorty.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/ReactAndMorty",
    description:
      "React website conencted to the Rick and Morty App loading all the characters from the the serie",
    title: "Rick and Morty Web App"
  },
  {
    css: simpleStore,
    height: 700,
    demo: "http://saleitemshooksanimation.s3-website-us-west-2.amazonaws.com",
    github: "https://github.com/eduardolanda/StoreHooksReactAnimation",
    description:
      "Simple Store App, similar to a todo list to keep track of items bought.",
    title: "Simple Store"
  }
];
